export const groqImageInlineAsset = /* groq */ `asset->{
    _id,
    originalFilename,
    altText,
    "width": metadata.dimensions.width,
    "height": metadata.dimensions.height
}`;

export default (imageField: string) => /* groq */ `${imageField} {
    ...,
    ${groqImageInlineAsset}
}`;
