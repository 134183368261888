export function verifyDataFound<T>(
  data: Ref<T | null>,
  requiredKeys: (keyof T)[] = [],
): T {
  const dataValue = unref(data);

  if (!dataValue || Object.keys(dataValue).length === 0) {
    throw createError({ statusCode: 404, fatal: true, message: "not found" });
  }

  for (const key of requiredKeys) {
    if (!dataValue[key]) {
      throw createError({ statusCode: 404, fatal: true, message: "not found" });
    }
  }

  return dataValue;
}
