const ADDITIONS = {
  _id: "cmsId",
  _type: "cmsType",
  _key: "cmsKey",
};

/**
 * Vue doesn't like undescore props, so we patch
 * all of our data objects
 *
 * Adds a cms* equivalent to known underscore props
 * Ignores reactivity and mutates, since it never changes
 * and we don't want to interfere too much
 *
 * Ideas to improve the patching mechanism welcome...
 *
 * @param o Response object from sanity's fetch
 */
export function patchCmsObjects(o: any): any {
  if (Array.isArray(o)) {
    o.forEach((e) => patchCmsObjects(e));
  } else if (typeof o === "object" && o !== null) {
    for (const key in o) {
      // Recurse into objects
      patchCmsObjects(o[key]);
      // Add cms* version if necessary
      const newKey = ADDITIONS[key as keyof typeof ADDITIONS];
      if (newKey) {
        o[newKey] = o[key];
      }
    }
  } else {
    // do nothing
  }
}
