import type { AsyncData, AsyncDataOptions } from "#app";
import { useRuntimeConfig } from "#imports";
import config from "~/config";

// See https://github.com/nuxt-modules/sanity/blob/7e07ecc4f3387ba7287524e27ab454b9d2e9b360/src/runtime/composables.ts#L55
interface UseSanityQueryOptions<T> extends AsyncDataOptions<T> {
  client?: string;
}

// Thin wrapper around useSanityQuery to cancel request when an error
// is present. When a 404 hit on server side, the content of the nuxt
// app is empty, so use this information to avoid requests in such cases
export const useCmsQuery = <T = unknown, E = Error>(
  query: string,
  _params?: Record<string, any>,
  _options: UseSanityQueryOptions<T> = {},
): AsyncData<T | null, E> | Promise<{ data: Ref<null> }> => {
  const routes = useRoute();
  // Use "?cmsDrafts=true" parameter to define if we want to fetch draft data
  const shouldFetchDrafts = routes.query?.cmsDrafts === "true";

  const { isSSREnabled } = useRuntimeConfig().public;
  const error = useError();
  const clientPageIsEmpty =
    process.client && !document.querySelector("#__nuxt")?.children?.length;
  const hasError = (error && unref(error)) || (isSSREnabled && clientPageIsEmpty);

  return hasError
    ? Promise.resolve({ data: ref(null) })
    : useSanityQuery(query, _params, {
        client: shouldFetchDrafts ? config.sanityDraftDataClientName : undefined,
        ..._options,
      }).then(
        (v) => {
          patchCmsObjects(unref(v.data));
          return v;
        },
        (error) => error,
      );
};
